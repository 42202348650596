<template src="./profile.html" />

<script>
import moment from "moment"
import mixins from "@/mixins/mixins.js"
import { useStore } from "@/store/store.js"

export default {
  mixins: [mixins],
  data() {
    return {
      user: [],
      name: null,
      cpf: null,
      rg: null,
      email: null,
      phone: null,
      invalidFields: {
        email: false,
        phone: false,
        birthdate: false,
        zipcode: false,
        street: false,
        number: false,
        city: false,
        state: false,
      },
      zipcode: null,
      street: null,
      number: null,
      complement: null,
      city: null,
      state: null,
      validZip: true,
      disableStreetEdit: true,
      textDangerZipcode: "",
      birthdate: null,
      genderOptions: [
        { name: "Feminino", value: "F" },
        { name: "Masculino", value: "M" },
      ],
      gender: null,
      imageData: "",
      clickedPhoto: false,

    };
  },
  setup () {
    const store = useStore()
    return {
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },
  created() {
    this.imageData = `data:image/png;base64,${this.$session.get("photo")}`
  },
  mounted () {
    this.getUserData()
  },
  methods: {
    getUserData() {
      var vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "patient/personal-data", {
          headers: {
            Authorization: vm.$session.get("bearer"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.user = response.data;
            vm.email = vm.user.email;
            vm.name = vm.user.name;
            vm.rg = vm.user.rg;
            vm.phone = vm.user.phone? vm.maskCelPhone(vm.user.phone): "";
            if (vm.testNullUndefinedEmpty(vm.user.birthdate)) {
              vm.birthdate = vm.user.birthdate;
            }
            vm.cpf = vm.user.cpf? vm.maskCpf(vm.user.cpf): "";
            vm.gender = vm.user.gender;
            vm.birthdate = vm.user.birthdate;
            if (vm.user.address) {
              vm.zipcode = vm.user.address.zipcode;
              vm.city = vm.user.address.city;
              vm.state = vm.user.address.state;
              setTimeout(() => {
                vm.number = parseInt(vm.user.address.number);
                vm.complement = vm.user.address.complement;
                vm.street = vm.user.address.street;
              }, 500);
            } else {
              if (vm.$session.get("address")) {
                const address = vm.$session.get("address");
                vm.zipcode = address.zipcode;
                vm.street = address.street;
                vm.city = address.city;
                vm.state = address.state;
                setTimeout(() => {
                  vm.number = parseInt(address.number);
                  vm.complement = address.complement;
                }, 500);
              }
            }
          }
        })
        .catch(function (error) {
          console.log({
            error: error,
            method: "getUserData",
            endpoint: "patient/personal-data",
            http_method: "GET",
          });
          vm.showNotifyError(
            "Não foi possível realizar a requisição e obter os dados cadastrais"
          );
        });
    },
    validateFields() {
      const vm = this;
      let valid = true;
      vm.invalidFields.email = false;
      vm.invalidFields.phone = false;
      const regexCell = /\(*[1-9]{2}\)* ?(?:[2-8]|9[1-9])[0-9]{3}-*[0-9]{4}$/gm;

      if (vm.validateEmail(vm.email) === false) {
        valid = false;
        vm.invalidFields.email = true;
      }
      if (regexCell.test(vm.phone) === false) {
        valid = false;
        vm.invalidFields.phone = true;
      }
      if(vm.testNullUndefinedEmpty(vm.birthdate)){
        valid = false;
        vm.invalidFields.birthdate = true;
      }
      if(vm.testNullUndefinedEmpty(vm.gender)){
        valid = false;
        vm.invalidFields.gender = true;
      }
      if (vm.testNullUndefinedEmpty(vm.zipcode)) {
        valid = false;
        vm.invalidFields.zipcode = true;
        vm.invalidFields.street = true;
        vm.invalidFields.city = true;
        vm.invalidFields.state = true;
        vm.textDangerZipcode = "Campo obrigatório";
      } else if (vm.validZip == false) {
        valid = false;
        vm.invalidFields.zipcode = true;
        vm.invalidFields.street = true;
        vm.invalidFields.city = true;
        vm.invalidFields.state = true;
        vm.textDangerZipcode = " Digite um CEP válido";
      }
      if(vm.testNullUndefinedEmpty(vm.street)){
        valid = false;
        vm.invalidFields.street = true;
      }
      if (vm.testNullUndefinedEmpty(vm.number)) {
        valid = false;
        vm.invalidFields.number = true;
      }
      if (valid === true) {
        vm.updateRegistrationData();
      } else {
        vm.showNotifyWarning("Existem campos obrigatórios inválidos ou não preenchidos", 4000);
      }
    },
    openPopupCorreios() {
      const vm = this;
      vm.$bvModal.show("profile-popup-correios");
    },
    async getAddress() {
      const zipcode = this.zipcode.replace(/\D/g, "")
      this.validZip = zipcode && zipcode.length === 8

      if (this.validZip) {
        const response = await this.getAddressByZipcode(zipcode)
        this.validZip = !!response && !!response.address
        if (this.validZip) {          
          this.street = response.address.street
          this.city = response.address.city
          this.state = response.address.state
          this.neighborhood = response.address.neighborhood
          
          this.invalidFields.zipcode = false
          this.invalidFields.city = false
          this.invalidFields.street = false
          this.invalidFields.state = false

        }
      }

      if (!this.validZip) {
        this.clearAddressFields()
        this.invalidFields.zipcode = true
        this.textDangerZipcode = "Insira um CEP válido"
        this.invalidFields.city = true
        this.invalidFields.street = true
        this.invalidFields.state = true
      }
    },
    clearAddressFields() {
      this.street = null
      this.number = null
      this.complement = null
      this.city = null
      this.state = null
    },
    updateRegistrationData() {
      const vm = this;
      vm.user.email = vm.email;
      vm.user.phone = vm.phone;
      vm.user.gender = vm.gender;
      const dateBirthFormatToBr = moment(String(vm.birthdate)).locale("pt-br").format("L"); 
      vm.user.birthdate = dateBirthFormatToBr;
      vm.user.address = {
        zipcode: vm.zipcode,
        street: vm.street,
        city: vm.city,
        state: vm.state,
        number: vm.number,
        complement: vm.complement,
      };
      vm.axios
        .put(vm.$store.state.filooServer + "patient/personal-data", vm.user, {
          headers: {
            Authorization: vm.$session.get("bearer"),
          },
        })
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            vm.showNotifySuccess(
              "Operação realizada!",
              "Os dados cadastrais foram alterados com sucesso.",
              3500
            );
            vm.$nextTick(function () {
              vm.getUserData();
            });
          } else {
            vm.showNotifyError(
              "Não foi possível atualizar os dados cadastrais"
            );
          }
        })
        .catch(function (error) {
          console.log({
            error: error,
            method: "updateRegistrationData",
            endpoint: "patient/personal-data",
            http_method: "PUT",
          });
          vm.showNotifyError(
            "Não foi possível realizar a requisição e atualizar os dados cadastrais"
          );
        });
    },
    previewImage (event) {
      const input = event.target
      const file = input.files[0]
      const reader = new FileReader()
      const formData = new FormData()

      reader.onloadend = function () {
        this.imageData = reader.result
      }
      
      reader.readAsDataURL(file)
      formData.append("file", file, file.name)

      this.axios.post(
        `${this.$store.state.filooServer}patient/photo/upload`,
        formData,
        {
          headers: { Authorization: this.$session.get("bearer") },
          "Content-Type": "multipart/form-data",
        }
      )
        .then(({status}) => {
          if (status === 200) { this.getMe() }
        })
        .catch((error) => console.error(error))
    },
    getMe() {
      this.axios.get(
        `${this.$store.state.filooServer}me`,
        { headers: { Authorization: this.$session.get("bearer") }}
      )
        .then(({ data }) => {
          this.options = data
          this.$session.set("photo", this.options.photo)
          location.reload()
        })
    },
  },
  watch: {
    email: function (newVal) {
      const vm = this;
      if (vm.testNullUndefinedEmpty(newVal) == false) {
        vm.invalidFields.email = false;
      }
    },
    phone: function (newVal) {
      const vm = this;
      if (vm.testNullUndefinedEmpty(newVal) == false) {
        vm.invalidFields.phone = false;
      }
    },
    zipcode: function (newVal) {
      const vm = this;
      vm.street = null;
      vm.city = null;
      vm.state = null;
      vm.number = null;
      vm.complement = null;
      if (newVal.split("").length < 9) return;
      if (vm.testNullUndefinedEmpty(newVal) == false) {
        vm.getAddress();
      } else {
        vm.invalidFields.zipcode = true;
      }
    },
    birthdate: function (newVal) {
      const vm = this;
      if(vm.testNullUndefinedEmpty(newVal) === false){
        vm.invalidFields.birthdate = false;
      }
    },
    gender: function (newVal) {
      const vm = this;
      if(vm.testNullUndefinedEmpty(newVal) === false){
        vm.invalidFields.gender = false;
      }
    },
  },
};
</script>

<style lang="scss" src="./profile.scss" />
